import styled from 'styled-components';
import { mediaMax } from '@styles/utils/responsive';

export const LayoutContent = styled.div`
  max-width: 1190px;
  min-height: 100vh;
  padding: 115px 25px 70px;
  margin: 0 auto;

  @media screen and ${mediaMax.tablet} {
    padding: 90px 25px 50px;
  }
`;
