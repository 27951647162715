import styled from 'styled-components';
import { mediaMax } from '@styles/utils/responsive';

export const PaymentCardsIconsContainer = styled.div`
  display: flex;
  align-items: center;
  grid-row: 2;

  @media screen and ${mediaMax.tablet} {
    grid-row: 3;
  }
`;
