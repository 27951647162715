import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Lazy, pipe } from 'fp-ts/function';
import { HttpError, HttpTask } from '@core/http/model';

import * as TE from 'fp-ts/TaskEither';
import { defaultAxiosInstance } from '@core/http/config';

function transformRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E> {
  return pipe(
    TE.tryCatch(request, err => HttpError.fromAxiosError<E>(err as AxiosError)),
    TE.map(res => res.data),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return transformRequest(() => defaultAxiosInstance.get(url, config));
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E> {
  return transformRequest(() => defaultAxiosInstance.post(url, data, config));
}

export const httpService = {
  get,
  post,
};
