import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import { mediaMax } from '@styles/utils/responsive';

export const CreationFormDescription = styled.p`
  padding-bottom: 45px;
  font-weight: ${fontWeight.light};
  line-height: 2.29;
`;

export const CreationFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 60px;

  @media screen and ${mediaMax.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const CreationFormCheckboxContainer = styled.div`
  display: flex;
  align-items: flex-end;

  input {
    margin-bottom: 3px;
  }

  label {
    padding-left: 10px;
    font-weight: ${fontWeight.light};
    cursor: pointer;
  }
`;

export const CreationFormAdmin = styled.div`
  grid-column: span 2;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
  }

  @media screen and ${mediaMax.tablet} {
    grid-column: 1;

    > div {
      grid-template-columns: 1fr;
    }
  }
`;

export const CreateFormCity = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
  padding-top: 20px;
`;

export const CreateFormCountry = styled.div`
  padding-top: 10px;
`;

export const CreateFormCountryWarningLabel = styled.p`
  font-size: ${fontSize.small};
  color: ${tertiaryColor(700)};
`;

export const CreateFormSiretDescription = styled.p`
  padding-top: 8px;
  font-size: ${fontSize.small};
  color: ${tertiaryColor(800)};
  line-height: 15px;
`;

export const CreateFormCgvContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 20px;
  padding: 15px 20px;
  margin: 0 0 30px;
  background: #f5f5f5;

  > input {
    margin-top: 7px;
  }

  > label {
    font-weight: ${fontWeight.light};
    line-height: 2.29;
    max-width: 550px;
    cursor: pointer;

    > a {
      color: ${primaryColor};
      text-decoration: none;
    }
  }
`;
