import React, { FC, useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import ErrorPage from '@shared/component/error/ErrorPage';
import SubscriptionCreationPage from '@modules/subscription/CreationPage';
import PaymentPage from '@modules/subscription/PaymentPage';
import PaymentSuccessPage from '@modules/subscription/PaymentSuccessPage';
import SubscriptionDetailPage from '@modules/subscription/DetailPage';
import { ErrorBoundary } from '@sentry/react';

const RootRoutes: FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <ErrorBoundary fallback={<ErrorPage internal />}>
      <Routes>
        <Route index element={<SubscriptionCreationPage />} />

        <Route path="/paiement/validation" element={<PaymentSuccessPage />} />
        <Route path="/paiement/:id" element={<PaymentPage />} />

        <Route path="/:token" element={<SubscriptionDetailPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </ErrorBoundary>
  );
};

export default RootRoutes;
