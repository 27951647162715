import styled, { css } from 'styled-components';
import { fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import { mediaMax, mediaMin } from '@styles/utils/responsive';

export const NavContainer = styled.nav<{ $open: boolean }>`
  height: 100%;
  padding-left: 75px;

  @media screen and ${mediaMax.desktop} {
    position: absolute;
    top: calc(100% + 1px);
    max-height: ${props => (props.$open ? '500px' : 0)};
    overflow: hidden;
    left: 0;
    width: 100%;
    height: auto;
    padding-left: 0;
    background: ${tertiaryColor(100)};
    transition: max-height 0.45s ease-in-out;

    ${props =>
      props.$open &&
      css`
        border-bottom: 1px solid ${tertiaryColor(200)};
      `}
  }
`;

export const NavList = styled.ul`
  display: flex;
  height: 100%;

  @media screen and ${mediaMax.desktop} {
    flex-direction: column;
    padding: 10px 0;
  }
`;

export const NavElement = styled.li`
  position: relative;
  height: 100%;
  cursor: pointer;

  > a,
  span {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    padding: 10px 15px 0;
    color: ${tertiaryColor(800)};
    font-weight: ${fontWeight.medium};
    text-decoration: none;
    transition: color 0.25s ease-in-out;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: ${primaryColor};
      transform: scaleX(0);
      transition: transform 0.25s ease-in-out;
      transform-origin: left;
    }

    @media screen and ${mediaMax.desktop} {
      padding: 10px 15px;
    }
  }

  > span {
    &:after {
      content: '';
      width: 4px;
      height: 4px;
      margin-top: -1px;
      margin-left: 7px;
      border-top: 2px solid #7d7d7d;
      border-right: 2px solid #7d7d7d;
      transform: rotate(135deg);
    }
  }

  &:hover {
    > a,
    span {
      color: ${tertiaryColor(900)};

      &:before {
        transform: scaleX(1);
      }
    }

    @media screen and ${mediaMin.desktop} {
      > ul {
        display: block;
      }
    }
  }

  &:last-child {
    > a,
    span {
      color: ${primaryColor};
    }
  }

  @media screen and ${mediaMax.desktop} {
    padding: 0;
    line-height: 20px;
  }
`;

export const NavSubMenu = styled.ul<{ $open: boolean }>`
  position: absolute;
  display: none;
  left: 0;
  top: 100%;
  width: auto;
  min-width: 160px;
  background: #fff;
  border: 1px solid rgb(226, 226, 226);
  border-radius: 2px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  z-index: 10000;

  > li {
    > a {
      position: relative;
      display: block;
      padding: 10px 20px;
      white-space: nowrap;
      color: ${tertiaryColor(800)};
      text-decoration: none;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: ${primaryColor};
        transform: scaleX(0);
        transition: transform 0.25s ease-in-out;
        transform-origin: left;
      }

      &:hover {
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }

  @media screen and ${mediaMax.desktop} {
    position: relative;
    display: block;
    top: auto;
    box-shadow: none;
    border: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s linear;

    > li {
      > a {
        padding: 10px 20px;
      }
    }

    ${props =>
      props.$open &&
      css`
        max-height: 200px;
      `}
  }
`;
