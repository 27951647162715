import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import { mediaMax } from '@styles/utils/responsive';

export const FooterContainer = styled.footer`
  background-color: #47413d;
`;

export const FooterTop = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  padding: 90px 25px 95px;
  max-width: 1190px;
  margin: 0 auto;
  color: ${tertiaryColor(100)};

  @media screen and ${mediaMax.desktop} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 35px 20px;
    padding: 35px 25px 50px;

    > div {
      &:first-child,
      &:last-child {
        grid-column: span 2;
      }
    }
  }
`;

export const FooterFirstColumn = styled.div`
  > p {
    font-size: ${fontSize.small};

    &:first-of-type {
      padding: 20px 0 10px;
    }

    > span {
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid ${tertiaryColor(100)};
    }
  }
`;

export const FooterList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px;

  h3 {
    padding-bottom: 15px;
    font-weight: ${fontWeight.medium};
  }

  a,
  p {
    color: ${tertiaryColor(100)};
    font-size: ${fontSize.small};
  }

  a {
    text-decoration: none;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: ${primaryColor};
    }
  }

  @media screen and ${mediaMax.desktop} {
    padding: 0;
  }
`;

export const FooterSocial = styled.div`
  display: grid;
  grid-template: 28px / repeat(4, 28px);
  grid-column-gap: 18px;
  padding-top: 30px;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    border-radius: 100%;
  }
`;

export const FooterBottom = styled.div`
  padding: 20px 25px;
  border-top: 1px solid ${tertiaryColor(200)};
  color: ${tertiaryColor(300)};
  text-align: center;
`;
