import React, { FC, PropsWithChildren } from 'react';

import * as Styled from './SubscriptionLayout.styles';

import portailPro from '@assets/images/portailpro.png';
import portailPro2x from '@assets/images/portailpro@2x.png';
import portailPro3x from '@assets/images/portailpro@3x.png';

import jardiAlerte from '@assets/images/jardialerte.png';
import jardiAlerte2x from '@assets/images/jardialerte@2x.png';
import jardiAlerte3x from '@assets/images/jardialerte@3x.png';

import portailPro2 from '@assets/images/portailpro2.png';
import portailPro22x from '@assets/images/portailpro2@2x.png';
import portailPro23x from '@assets/images/portailpro2@3x.png';

import simuateur from '@assets/images/simulateur.png';
import simulateur2x from '@assets/images/simulateur@2x.png';
import simulateur3x from '@assets/images/simulateur@3x.png';

import paiement from '@assets/images/paiement.png';
import paiement2x from '@assets/images/paiement@2x.png';
import paiement3x from '@assets/images/paiement@3x.png';

interface SubscriptionLayoutProps {
  step: 'creation' | 'detail' | 'payment';
}

const SubscriptionLayout: FC<PropsWithChildren<SubscriptionLayoutProps>> = ({ step, children }) => {
  return (
    <Styled.SubscriptionLayoutContainer>
      <Styled.SubscriptionLayoutHeader>
        <Styled.SubscriptionLayoutHeaderText>
          <h1>Souscription à l’offre Team Pro</h1>

          <span>Portail pro et application mobile</span>
        </Styled.SubscriptionLayoutHeaderText>
        <Styled.SubscriptionLayoutHeaderImage>
          <img src={portailPro} srcSet={`${portailPro2x} 2x, ${portailPro3x} 3x`} alt="Portail Pro" />
        </Styled.SubscriptionLayoutHeaderImage>
      </Styled.SubscriptionLayoutHeader>

      <Styled.SubscriptionLayoutContent>
        <Styled.SubscriptionLayoutImages>
          <div>
            {step === 'payment' ? (
              <>
                <img src={paiement} srcSet={`${paiement2x} 2x, ${paiement3x} 3x`} alt="Paiement" />
              </>
            ) : (
              <>
                <img src={jardiAlerte} srcSet={`${jardiAlerte2x} 2x, ${jardiAlerte3x} 3x`} alt="Jardi Alerte" />
                <img src={portailPro2} srcSet={`${portailPro22x} 2x, ${portailPro23x} 3x`} alt="Portail Pro" />
                <img src={simuateur} srcSet={`${simulateur2x} 2x, ${simulateur3x} 3x`} alt="Simulateur" />
              </>
            )}
          </div>
        </Styled.SubscriptionLayoutImages>

        <Styled.SubscriptionLayoutForm>
          <Styled.SubscriptionLayoutNav>
            <Styled.SubscriptionLayoutNavItem $active={step === 'creation' || step === 'detail'}>
              1. {step === 'creation' ? 'Création de compte' : 'Vos informations'}
            </Styled.SubscriptionLayoutNavItem>
            <Styled.SubscriptionLayoutNavItem $active={step === 'payment'}>
              2. Paiement sécurisé
            </Styled.SubscriptionLayoutNavItem>
          </Styled.SubscriptionLayoutNav>

          {children}
        </Styled.SubscriptionLayoutForm>
      </Styled.SubscriptionLayoutContent>
    </Styled.SubscriptionLayoutContainer>
  );
};

export default SubscriptionLayout;
