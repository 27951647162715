import React, { ChangeEventHandler, FC, useState } from 'react';

import * as Styled from './CreationForm.styles';
import FormContent from '@shared/component/form/FormContent';
import { Button, Checkbox, FormLabel, FormSubLabel, Input, Select } from '@styles/shared';
import { useForm } from 'react-hook-form';
import {
  CompanyKind,
  companyKindLabel,
  Country,
  countryLabel,
  CreateCompanyFormBody,
  createCompanySchema,
} from '@modules/subscription/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@styles/shared/error';
import * as O from 'fp-ts/Option';
import { renderOptional } from '@shared/utils/render';

interface CreationFormProps {
  loading: boolean;
  error: O.Option<string>;
  onSubmit: (company: CreateCompanyFormBody) => void;
}

const CreationForm: FC<CreationFormProps> = ({ loading, error, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CreateCompanyFormBody>({
    resolver: zodResolver(createCompanySchema),
    defaultValues: {
      kind: '' as any,
      unep: false,
      email: '',
      company: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      country: Country.France,
      postalCode: '',
      city: '',
      siret: '',
      address: {
        firstLine: '',
        secondLine: null,
        postalCode: '',
        city: '',
        country: Country.France,
      },
    },
  });

  const watchKind = watch('kind');

  const [cgvAccepted, setCgvAccepted] = useState<boolean>(false);
  const [cgvError, setCgvError] = useState<boolean>(false);

  const handleCgvAcceptationChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;

    setCgvError(false);
    setCgvAccepted(checked ?? false);
  };

  const handleCreateCompany = (company: CreateCompanyFormBody) => {
    if (!cgvAccepted) {
      setCgvError(true);
    } else {
      onSubmit(company);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreateCompany)} noValidate>
      <FormContent title="Création de compte">
        <Styled.CreationFormDescription>
          Veuillez remplir le formulaire suivant, pour souscrire à notre offre et bénéficier de l’accès à notre portail
          PRO et à des fonctionnalités avancées sur notre application mobile.
        </Styled.CreationFormDescription>

        <Styled.CreationFormGrid>
          <div>
            <FormLabel required htmlFor="kind">
              Votre univers
            </FormLabel>
            <Select id="kind" {...register('kind')} error={!!errors.kind}>
              <option value="" disabled>
                Sélectionner
              </option>

              {Object.keys(companyKindLabel).map(kind => (
                <option key={kind} value={kind}>
                  {companyKindLabel[kind as CompanyKind]}
                </option>
              ))}
            </Select>
          </div>

          <Styled.CreationFormCheckboxContainer>
            {CompanyKind.Landscaper === watchKind ? (
              <>
                <Checkbox id="unep" {...register('unep')} />
                <label htmlFor="unep">Affilié UNEP</label>
              </>
            ) : null}
          </Styled.CreationFormCheckboxContainer>

          <div>
            <FormLabel required htmlFor="email">
              E-mail
            </FormLabel>

            <Input type="email" id="email" {...register('email')} error={!!errors.email} />
          </div>

          <div />

          <div>
            <FormLabel required htmlFor="company">
              Nom Entreprise
            </FormLabel>

            <Input id="name" {...register('company')} error={!!errors.company} />
          </div>

          <div />

          <div>
            <FormLabel required htmlFor="phoneNumber">
              Téléphone
            </FormLabel>

            <Input id="phoneNumber" {...register('phoneNumber')} error={!!errors.phoneNumber} />
          </div>

          <div />

          <Styled.CreationFormAdmin>
            <FormLabel as="p" required>
              Administrateur
            </FormLabel>

            <div>
              <div>
                <Input id="firstName" {...register('firstName')} error={!!errors.firstName} />
                <FormSubLabel htmlFor="firstName">Prénom</FormSubLabel>
              </div>

              <div>
                <Input id="lastName" {...register('lastName')} error={!!errors.lastName} />
                <FormSubLabel htmlFor="lastName">Nom</FormSubLabel>
              </div>
            </div>
          </Styled.CreationFormAdmin>

          <div>
            <FormLabel as="p" required>
              Localisation
            </FormLabel>

            <Select id="country" {...register('country')} error={!!errors.country}>
              {Object.keys(countryLabel).map(country => (
                <option key={country} value={country}>
                  {countryLabel[country as Country]}
                </option>
              ))}
            </Select>
            <FormSubLabel htmlFor="country">Pays</FormSubLabel>
            <Styled.CreateFormCountryWarningLabel>
              Souscription possible uniquement en France
            </Styled.CreateFormCountryWarningLabel>

            <Styled.CreateFormCity>
              <div>
                <Input id="postalCode" {...register('postalCode')} error={!!errors.postalCode} />
                <FormSubLabel htmlFor="postalCode">Code postal</FormSubLabel>
              </div>

              <div>
                <Input id="city" {...register('city')} error={!!errors.city} />

                <FormSubLabel htmlFor="city">Ville</FormSubLabel>
              </div>
            </Styled.CreateFormCity>
          </div>

          <div />

          <div>
            <FormLabel as="p" required>
              Adresse de facturation
            </FormLabel>

            <div>
              <Input id="address.firstLine" {...register('address.firstLine')} error={!!errors.address?.firstLine} />
              <FormSubLabel htmlFor="address.firstLine">Adresse de facturation</FormSubLabel>
            </div>

            <div>
              <Input id="address.secondLine" {...register('address.secondLine')} error={!!errors.address?.secondLine} />
              <FormSubLabel htmlFor="address.secondLine">Complément (optionnel)</FormSubLabel>
            </div>

            <Styled.CreateFormCountry>
              <Select id="address.country" {...register('address.country')} error={!!errors.address?.country}>
                {Object.keys(countryLabel).map(country => (
                  <option key={country} value={country}>
                    {countryLabel[country as Country]}
                  </option>
                ))}
              </Select>
              <FormSubLabel htmlFor="address.country">Pays</FormSubLabel>
            </Styled.CreateFormCountry>

            <Styled.CreateFormCity>
              <div>
                <Input
                  id="address.postalCode"
                  {...register('address.postalCode')}
                  error={!!errors.address?.postalCode}
                />
                <FormSubLabel htmlFor="address.postalCode">Code postal</FormSubLabel>
              </div>

              <div>
                <Input id="address.city" {...register('address.city')} error={!!errors.city} />

                <FormSubLabel htmlFor="address.city">Ville</FormSubLabel>
              </div>
            </Styled.CreateFormCity>
          </div>

          <div />

          <div>
            <FormLabel required htmlFor="siret">
              Code Entreprise
            </FormLabel>
            <Input id="siret" {...register('siret')} error={!!errors.siret} />
            <Styled.CreateFormSiretDescription>Exemple : numéro de SIRET</Styled.CreateFormSiretDescription>
          </div>
        </Styled.CreationFormGrid>
      </FormContent>

      <Styled.CreateFormCgvContainer>
        <Checkbox id="cgv-acceptation" checked={cgvAccepted} onChange={handleCgvAcceptationChange} />

        <label htmlFor="cgv-acceptation">
          J’accepte les{' '}
          <a
            href="https://www.platform.garden/wp-content/uploads/2016/12/ConditionsGeneralesVentes-BDC-V2.pdf"
            target="_blank"
            rel="noreferrer">
            {' '}
            conditions générales de ventes plateforme.Garden
          </a>{' '}
          et notamment l’article 12 relatif à la Protection des données à caractère personnel.
        </label>
      </Styled.CreateFormCgvContainer>

      {cgvError && <ErrorMessage>Veuillez accepter les conditions générales de ventes.</ErrorMessage>}

      {renderOptional(error, error => (
        <ErrorMessage>{error}</ErrorMessage>
      ))}

      <Button type="submit" $loading={loading} disabled={loading}>
        Valider
      </Button>
    </form>
  );
};

export default CreationForm;
