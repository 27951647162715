import React, { FC, useCallback } from 'react';
import SubscriptionLayout from '@layout/subscription/SubscriptionLayout';
import PaymentForm from '@modules/subscription/components/payment/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import { Elements } from '@stripe/react-stripe-js';
import { CustomerId, StripePromotionCodeId } from '@modules/subscription/model';
import { renderHttpRemoteData } from '@shared/utils/render';
import * as SubscriptionService from './service';
import { HttpError, useFetchTask } from '@core/http';
import { useLocation, useParams } from 'react-router-dom';
import { pipe } from 'fp-ts/function';
import { getStringQuery, parseQueries, queriesToCamelCase } from '@shared/utils/queries';

import { z } from 'zod';
import { sequenceS } from 'fp-ts/Apply';
import config from '@config/index';
import { logSentryHttpError } from '@shared/modules/sentry/utils';

const stripePromise = loadStripe(config.VITE_STRIPE_API_KEY, { locale: 'fr' });

const PaymentPage: FC = () => {
  const customerId = useParams<{ id: CustomerId }>().id as CustomerId;

  const location = useLocation();

  const fetchTask = useCallback(() => {
    const contextTask = pipe(
      T.fromIO(() => {
        const queries = queriesToCamelCase(parseQueries(location.search));

        const email = pipe(
          O.fromNullable(getStringQuery(queries, 'email')),
          O.filter(email => z.string().email().safeParse(email).success),
        );

        const name = O.fromNullable(getStringQuery(queries, 'name'));

        const creationReason = O.fromNullable(getStringQuery(queries, 'creationReason'));

        const promotionCode = O.some(getStringQuery<StripePromotionCodeId>(queries, 'promotionCode'));

        return sequenceS(O.Apply)({ email, name, creationReason, promotionCode });
      }),
      TE.fromTaskOption(() => HttpError.notFound),
    );

    return pipe(
      TE.Do,
      TE.bind('context', () => contextTask),
      TE.bind('price', ({ context }) => SubscriptionService.getFirstPrice(context.promotionCode)),
      TE.orElseFirstIOK(error => () => {
        logSentryHttpError('[http] error on payment page load', error);
      }),
    );
  }, [location.search]);

  const [data] = useFetchTask(fetchTask);

  return (
    <SubscriptionLayout step="payment">
      <Elements stripe={stripePromise}>
        {renderHttpRemoteData(data, ({ price, context }) => (
          <PaymentForm customerId={customerId} price={price} context={context} />
        ))}
      </Elements>
    </SubscriptionLayout>
  );
};

export default PaymentPage;
