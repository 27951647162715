import React, { FC, useState } from 'react';
import SubscriptionLayout from '@layout/subscription/SubscriptionLayout';
import CreationForm from '@modules/subscription/components/creation/CreationForm';
import * as O from 'fp-ts/Option';
import * as TE from 'fp-ts/TaskEither';
import * as TO from 'fp-ts/TaskOption';
import * as T from 'fp-ts/Task';
import {
  CompanyId,
  CompanyKind,
  CreateCompanyFormBody,
  CustomerStorageValue,
  PaymentContext,
  PENDING_COMPANY_STORAGE_KEY,
} from '@modules/subscription/model';
import { pipe } from 'fp-ts/function';
import { useNavigate } from 'react-router-dom';
import * as SubscriptionService from './service';
import { filterEmptyStringToNullable } from '@shared/utils/string';
import storageService from '@core/storage/service';
import { queriesToSnakeCase, stringifyQueries } from '@shared/utils/queries';
import { logSentryHttpError } from '@shared/modules/sentry/utils';

const SubscriptionCreationPage: FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<O.Option<string>>(O.none);

  const [companyId, setCompanyId] = useState<O.Option<CompanyId>>(O.none);

  const handleSubmit = (company: CreateCompanyFormBody) => {
    setLoading(true);
    setError(O.none);

    const email = company.email.trim().toLowerCase();

    // Get companyId form state or create it
    const createOrGetCompanyId = pipe(
      companyId,
      O.fold(
        () =>
          pipe(
            SubscriptionService.createCompany({
              ...company,
              email,
              unep: CompanyKind.Landscaper === company.kind && company.unep,
            }),
            TE.chainIOK(({ id }) => () => {
              setCompanyId(O.some(id));

              return id;
            }),
          ),
        id => TE.right(id),
      ),
    );

    // store company on storage
    const storePendingCompany = (id: string) => {
      const storageValue: CustomerStorageValue = {
        customerId: id,
        email: email,
        company: company.company,
      };

      return pipe(
        storageService.setItem(PENDING_COMPANY_STORAGE_KEY, storageValue),
        T.map(() => id),
      );
    };

    // create or get customer from storage
    const createOrGetCustomerId = pipe(
      storageService.getItem<CustomerStorageValue>(PENDING_COMPANY_STORAGE_KEY),
      TO.filter(
        storageValue =>
          storageValue.company.trim().toLowerCase() === company.company.trim().toLowerCase() &&
          storageValue.email.trim().toLowerCase() === email.trim().toLowerCase(),
      ),
      TO.fold(
        () =>
          pipe(
            createOrGetCompanyId,
            TE.chain(id =>
              SubscriptionService.createCustomer({
                companyId: `${id}`,
                email,
                address: {
                  ...company.address,
                  name: company.company,
                  secondLine: filterEmptyStringToNullable(company.address.secondLine),
                },
              }),
            ),
            TE.chainTaskK(({ id }) => storePendingCompany(id)),
          ),
        storageValue => TE.right(storageValue.customerId),
      ),
    );

    pipe(
      createOrGetCustomerId,
      TE.fold(
        error =>
          T.fromIO(() => {
            logSentryHttpError('[http] Error on company or customer creation', error);

            setError(
              pipe(
                error.message,
                O.alt(() => O.some('Une erreur est survenue.')),
              ),
            );
          }),
        id =>
          T.fromIO(() => {
            const context: PaymentContext = {
              email,
              name: company.company,
              creationReason: 'customer-create',
              promotionCode: null,
            };

            navigate(
              { pathname: `/paiement/${id}`, search: stringifyQueries(queriesToSnakeCase(context)) },
              { replace: true },
            );
          }),
      ),
      T.chainIOK(() => () => setLoading(false)),
    )();
  };

  return (
    <SubscriptionLayout step="creation">
      <CreationForm loading={loading} error={error} onSubmit={handleSubmit} />
    </SubscriptionLayout>
  );
};

export default SubscriptionCreationPage;
