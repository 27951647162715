import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

export const Select = styled.select<{ error?: boolean }>`
  display: block;
  position: relative;
  height: 40px;
  width: 100%;
  padding: 0 14px;
  border: 1px solid ${props => (props.error ? primaryColor : '#b7b7b7')};
  background: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat transparent;
  background-position-x: 100%;
  background-position-y: 7px;
  font: inherit;
  font-size: ${fontSize.standard};
  font-weight: ${fontWeight.light};
  color: ${props => (props.error ? primaryColor : tertiaryColor(900))};
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 0.15s linear;

  option {
    color: ${tertiaryColor(900)};
  }
`;
