import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import { mediaMax } from '@styles/utils/responsive';

export const PaymentFormContent = styled.div`
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-gap: 30px 85px;

  @media screen and ${mediaMax.tablet} {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }
`;

export const PaymentFormDescription = styled.div`
  padding: 15px 20px;
  background: ${tertiaryColor(200)};
`;

export const PaymentFormDescriptionTitle = styled.p`
  font-size: 18px;
  color: ${tertiaryColor(800)};

  > span {
    color: ${primaryColor};
  }
`;

export const PaymentFormDescriptionCrossedPrice = styled.div`
  > span {
    &:first-child {
      margin-right: 15px;
      font-size: 25px;
      text-decoration: line-through;
    }

    &:last-child {
      font-size: 18px;
    }
  }
`;

export const PaymentFormDescriptionPrice = styled.p`
  font-size: 17px;
  line-height: 1.2;

  > span {
    font-size: 42px;
    color: ${primaryColor};
  }
`;

export const PaymentFormDescriptionText = styled.p`
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.light};
  color: ${tertiaryColor(800)};
`;

export const PaymentFormRadiosContainer = styled.div`
  display: flex;
  padding-bottom: 20px;

  > div {
    display: flex;

    > label {
      padding-left: 8px;
      cursor: pointer;
    }

    &:first-child {
      padding-right: 20px;
    }
  }
`;

export const PaymentFormCardContainer = styled.div`
  grid-row: 2;

  > p {
    font-size: ${fontSize.large};
    padding-bottom: 10px;

    &:after {
      content: '*';
      margin-left: 3px;
      color: ${primaryColor};
    }
  }
`;

export const PaymentFormSepaContainer = styled.div`
  > div {
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #b7b7b7;
    padding: 0 15px;
    margin-top: -1px;

    > * {
      flex: 1 1 auto;
    }

    &.StripeElement--invalid {
      border-color: ${primaryColor};
      z-index: 2;
    }
  }
`;

export const PaymentFormCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > input {
    display: block;
    grid-column: span 2;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #b7b7b7;
    outline: none;
    background: none;
    font-family: inherit;
    font-size: ${fontSize.small};

    &::placeholder {
      font-family: inherit;
      font-size: ${fontSize.small};
    }

    &.error {
      border-color: ${primaryColor};
      z-index: 2;
    }
  }

  > div {
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid #b7b7b7;
    padding: 0 15px;
    margin-top: -1px;

    &:nth-child(2) {
      grid-column: span 2;
    }

    &:last-child {
      margin-left: -1px;
    }

    > * {
      flex: 1 1 auto;
    }

    &.StripeElement--invalid {
      border-color: ${primaryColor};
      z-index: 2;
    }
  }
`;
