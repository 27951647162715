import React, { FC, PropsWithChildren } from 'react';
import Header from '@layout/header/Header';
import Footer from '@layout/footer/Footer';

import * as Styled from './Layout.styles';

const Layout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <>
      <Header />

      <Styled.LayoutContent>{children}</Styled.LayoutContent>

      <Footer />
    </>
  );
};

export default Layout;
