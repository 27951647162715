import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '@styles/theme';

import { GlobalStyles } from '@styles/global';
import Layout from '@layout/Layout';

import { BrowserRouter } from 'react-router-dom';
import RootRoutes from './routes';

const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Layout>
        <BrowserRouter>
          <RootRoutes />
        </BrowserRouter>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
