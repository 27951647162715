import styled, { css } from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import { mediaMax } from '@styles/utils/responsive';

export const SubscriptionLayoutContainer = styled.div``;

export const SubscriptionLayoutHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 253px;
  grid-gap: 20px;

  @media screen and ${mediaMax.desktop} {
    grid-template-columns: 1fr;
  }
`;

export const SubscriptionLayoutHeaderText = styled.div`
  padding-top: 15px;

  h1 {
    font-size: ${fontSize.h1};
    color: ${tertiaryColor(800)};
    text-transform: uppercase;

    &:after {
      content: '';
      display: block;
      width: 140px;
      height: 5px;
      background: ${primaryColor};
    }
  }

  span {
    display: inline-block;
    padding: 13px 20px;
    margin-top: 30px;
    font-size: ${fontSize.h3};
    background: ${tertiaryColor(700)};
    color: ${tertiaryColor(100)};
    line-height: 24px;
    text-transform: uppercase;
  }
`;

export const SubscriptionLayoutHeaderImage = styled.div`
  > img {
    max-width: 100%;
  }

  @media screen and ${mediaMax.desktop} {
    grid-row: 1;
  }
`;

export const SubscriptionLayoutContent = styled.div`
  display: grid;
  grid-template-columns: 255px 1fr;
  grid-column-gap: 60px;
  padding-top: 55px;

  @media screen and ${mediaMax.desktop} {
    grid-template-columns: 1fr;
    padding-top: 40px;
  }
`;

export const SubscriptionLayoutImages = styled.div`
  > div {
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 38px;
    padding-top: 40px;

    > img {
      max-width: 100%;
    }
  }

  @media screen and ${mediaMax.desktop} {
    display: none;
  }
`;

export const SubscriptionLayoutForm = styled.div``;

export const SubscriptionLayoutNav = styled.div`
  display: flex;
`;

export const SubscriptionLayoutNavItem = styled.div<{ $active: boolean }>`
  position: relative;
  padding: 10px 18px 8px;
  color: ${props => (props.$active ? tertiaryColor(800) : '#888888')};
  font-weight: ${fontWeight.medium};

  &:first-child {
    padding-left: 0;
  }

  ${props =>
    props.$active &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 3px;
        background: ${primaryColor};
      }
    `}
`;
