import { HttpRemoteData, HttpTask } from '@core/http/model';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import * as RD from 'fp-ts-remote-data';
import * as T from 'fp-ts/Task';
import isEqual from 'lodash.isequal';
import { pipe } from 'fp-ts/function';

function useCurrentArgs<P extends unknown[]>(...args: P) {
  const [currentArgs, setCurrentArgs] = useState(args);

  useEffect(() => {
    setCurrentArgs(old => (!isEqual(old, args) ? args : old));
  }, [args]);

  return currentArgs;
}

export function useFetchTask<P extends unknown[], R, E>(
  task: (...args: P) => HttpTask<R, E>,
  ...args: P
): [HttpRemoteData<R, E>, HttpTask<R, E>, boolean, Dispatch<SetStateAction<HttpRemoteData<R, E>>>] {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<HttpRemoteData<R, E>>(RD.pending);

  const currentArgs = useCurrentArgs(...args);

  const fetchData = useCallback(() => {
    setLoading(true);

    return pipe(
      task(...currentArgs),
      T.chainIOK(res => () => {
        setLoading(false);
        setData(RD.fromEither(res));

        return res;
      }),
    )();
  }, [currentArgs, task]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [data, fetchData, loading, setData];
}
