import FormContent from '@shared/component/form/FormContent';
import React, { FC, useState } from 'react';

import * as Styled from './PaymentForm.styles';
import { PaymentIntentResult, PaymentMethod, StripeElementStyle, StripeError } from '@stripe/stripe-js';
import { useTheme } from 'styled-components';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button, Radio } from '@styles/shared';
import { useForm } from 'react-hook-form';
import {
  CreateSubscriptionResponse,
  CustomerId,
  PaymentContext,
  PaymentError,
  PaymentFormBody,
  paymentSchema,
  PENDING_COMPANY_STORAGE_KEY,
  SubscriptionPrice,
} from '@modules/subscription/model';
import { zodResolver } from '@hookform/resolvers/zod';
import PaymentCardsIcons from '@modules/subscription/components/payment/cards/PaymentCardsIcons';

import * as SubscriptionService from '@modules/subscription/service';
import { constTrue, pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { useNavigate } from 'react-router-dom';
import GlobalLoader from '@shared/component/loader/global-loader/GlobalLoader';
import { renderNullable, renderOptional } from '@shared/utils/render';
import { ErrorMessage } from '@styles/shared/error';
import storageService from '@core/storage/service';
import { logSentryMessage } from '@shared/modules/sentry/utils';

interface PaymentFormProps {
  customerId: CustomerId;
  context: PaymentContext;
  price: SubscriptionPrice;
}

const PaymentForm: FC<PaymentFormProps> = ({ customerId, context, price }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<O.Option<PaymentError>>(O.none);

  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<PaymentFormBody>({
    resolver: zodResolver(paymentSchema),
    defaultValues: {
      type: 'sepa',
    },
  });

  const paymentType = watch('type');

  const stripeStyle: StripeElementStyle = {
    base: {
      fontFamily: theme.font.family,
      fontSize: theme.font.sizes.small,
      color: theme.colors.tertiary[800],
    },
    invalid: {
      color: theme.colors.primary,
    },
  };

  const handlePaymentTypeChange = (type: 'sepa' | 'card') => () => {
    setValue('type', type);
    setError(O.none);
  };

  const handlePay = (body: PaymentFormBody) => {
    if (stripe) {
      const handleStripeError = (error?: StripeError): PaymentError =>
        error?.message ? { type: 'stripe', message: error.message } : { type: 'technical' };

      const createPaymentMethod = (): TE.TaskEither<PaymentError, PaymentMethod> => {
        const createCardPaymentMethod = (name: string) =>
          pipe(
            TE.fromNullable<PaymentError>({ type: 'technical' })(elements?.getElement(CardNumberElement)),
            TE.chainTaskK(
              card => () =>
                stripe.createPaymentMethod({
                  type: 'card',
                  billing_details: {
                    name,
                  },
                  card,
                }),
            ),
          );

        const createSepaPaymentMethod = () =>
          pipe(
            TE.fromNullable<PaymentError>({ type: 'technical' })(elements?.getElement(IbanElement)),
            TE.chainTaskK(
              iban => () =>
                stripe?.createPaymentMethod({
                  type: 'sepa_debit',
                  billing_details: {
                    name: context.name,
                    email: context.email,
                  },
                  sepa_debit: iban,
                }),
            ),
          );

        return pipe(
          body.type === 'card' ? createCardPaymentMethod(body.name) : createSepaPaymentMethod(),
          TE.chainEitherK(({ paymentMethod, error }) => {
            if (paymentMethod) {
              return EI.right(paymentMethod);
            } else {
              return EI.left(handleStripeError(error));
            }
          }),
        );
      };

      const createSubscription: TE.TaskEither<PaymentError, CreateSubscriptionResponse> = pipe(
        SubscriptionService.createSubscription({
          customerId,
          priceId: price.id,
          creationReason: context.creationReason,
          promotionCodeId: context.promotionCode,
        }),
        TE.mapLeft(() => ({ type: 'technical' })),
      );

      const confirmPayment = (
        paymentMethod: PaymentMethod,
        subscription: CreateSubscriptionResponse,
      ): TE.TaskEither<PaymentError, boolean> => {
        const confirmCardPayment = TE.fromTask<PaymentIntentResult, PaymentError>(() =>
          stripe.confirmCardPayment(subscription.secret, { payment_method: paymentMethod.id }),
        );

        const confirmSepaPayment = TE.fromTask<PaymentIntentResult, PaymentError>(() =>
          stripe.confirmSepaDebitPayment(subscription.secret, { payment_method: paymentMethod.id }),
        );

        return pipe(
          body.type === 'card' ? confirmCardPayment : confirmSepaPayment,
          TE.chainEitherK(({ error }) => {
            if (error) {
              return EI.left(handleStripeError(error));
            } else {
              return EI.right(true);
            }
          }),
        );
      };

      const paymentPolling = (subscription: CreateSubscriptionResponse): TE.TaskEither<PaymentError, boolean> =>
        pipe(
          SubscriptionService.subscriptionPolling(subscription.id),
          TE.bimap(() => ({ type: 'polling' }), constTrue),
        );

      const paymentTask = pipe(
        TE.Do,
        TE.bind('paymentMethod', () => createPaymentMethod()),
        TE.bind('subscription', () => createSubscription),
        TE.chainFirst(({ paymentMethod, subscription }) => confirmPayment(paymentMethod, subscription)),
        TE.chainFirst(({ subscription }) => (body.type === 'card' ? paymentPolling(subscription) : TE.right(true))),
        TE.map(constTrue),
      );

      setError(O.none);
      setLoading(true);

      pipe(
        paymentTask,
        TE.fold(
          error =>
            T.fromIO(() => {
              logSentryMessage('[http] error on payment', 'error', {
                error: JSON.stringify(error),
              });

              setError(O.some(error));
              setLoading(false);
            }),
          () =>
            pipe(
              storageService.removeItem(PENDING_COMPANY_STORAGE_KEY),
              T.chainIOK(() => () => {
                navigate('/paiement/validation', { replace: true });
              }),
            ),
        ),
      )();
    }
  };

  const hasPollingError = pipe(
    error,
    O.exists(e => e.type === 'polling'),
  );

  return (
    <form onSubmit={handleSubmit(handlePay)} noValidate>
      {loading ? <GlobalLoader /> : null}

      <FormContent title="Paiement sécurisé">
        <Styled.PaymentFormContent>
          <Styled.PaymentFormDescription>
            <Styled.PaymentFormDescriptionTitle>
              Abonnement <span>TEAM PRO</span>
            </Styled.PaymentFormDescriptionTitle>

            {renderNullable(price.discount, discount => (
              <Styled.PaymentFormDescriptionCrossedPrice>
                <span>{price.unitAmount} &euro;</span>
                <span>-{discount.percentage}%</span>
              </Styled.PaymentFormDescriptionCrossedPrice>
            ))}

            <Styled.PaymentFormDescriptionPrice>
              <span>{price.discount?.unitAmount ?? price.unitAmount} &euro;</span> par AN
            </Styled.PaymentFormDescriptionPrice>
            <Styled.PaymentFormDescriptionText>Sans engagement</Styled.PaymentFormDescriptionText>
          </Styled.PaymentFormDescription>

          <Styled.PaymentFormCardContainer>
            <Styled.PaymentFormRadiosContainer>
              <div>
                <Radio
                  id="sepa"
                  name="payment-type"
                  checked={paymentType === 'sepa'}
                  onChange={handlePaymentTypeChange('sepa')}
                />
                <label htmlFor="sepa">Prélèvement SEPA</label>
              </div>

              <div>
                <Radio
                  id="card"
                  name="payment-type"
                  checked={paymentType === 'card'}
                  onChange={handlePaymentTypeChange('card')}
                />
                <label htmlFor="card">Carte bancaire</label>
              </div>
            </Styled.PaymentFormRadiosContainer>

            <p>Informations {paymentType === 'sepa' ? 'bancaires' : 'carte'}</p>

            {paymentType === 'sepa' ? (
              <Styled.PaymentFormSepaContainer>
                <IbanElement options={{ supportedCountries: ['SEPA'], style: stripeStyle }} />
              </Styled.PaymentFormSepaContainer>
            ) : (
              <Styled.PaymentFormCardGrid>
                <input
                  placeholder="Titulaire de la carte"
                  {...register('name')}
                  className={(errors as any).name ? 'error' : ''}
                />

                <CardNumberElement options={{ showIcon: true, style: stripeStyle }} />

                <CardExpiryElement options={{ style: stripeStyle }} />

                <CardCvcElement options={{ style: stripeStyle }} />
              </Styled.PaymentFormCardGrid>
            )}
          </Styled.PaymentFormCardContainer>

          {paymentType === 'card' ? <PaymentCardsIcons /> : null}
        </Styled.PaymentFormContent>
      </FormContent>

      {renderOptional(error, error => (
        <ErrorMessage>
          {error.type === 'stripe' ? (
            error.message
          ) : error.type === 'technical' ? (
            'Une erreur est survenue'
          ) : (
            <>
              Votre paiement a bien été enregistré mais nos services ne sont pas en mesure de valider votre abonnement
              pour le moment.
              <br />
              Veuillez contacter l'administrateur de la plateforme sur{' '}
              <a href="https://www.platform.garden/contact/">www.platform.garden/contact</a>.
            </>
          )}
        </ErrorMessage>
      ))}

      <Button type="submit" disabled={hasPollingError}>
        Payer
      </Button>
    </form>
  );
};

export default PaymentForm;
