import { DefaultTheme, ThemeProps } from 'styled-components';
import { pipe } from 'fp-ts/function';
import theme from '@styles/theme';
import * as R from 'fp-ts/Record';
import * as String from 'fp-ts/string';

export function fontFamily({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.font.family;
}

export const fontWeight = pipe(
  theme.font.weights,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<
      Record<Extract<keyof DefaultTheme['font']['weights'], string>, (props: ThemeProps<DefaultTheme>) => number>
    >,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.font.weights[key],
    }),
  ),
);

export const fontSize = pipe(
  theme.font.sizes,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<
      Record<Extract<keyof DefaultTheme['font']['sizes'], string>, (props: ThemeProps<DefaultTheme>) => string>
    >,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.font.sizes[key],
    }),
  ),
);
