import React, { ChangeEventHandler, FC, useState } from 'react';
import {
  AddressFormBody,
  addressSchema,
  Company,
  Country,
  countryLabel,
  PaymentContext,
  StripePromotionCodeId,
} from '@modules/subscription/model';
import * as O from 'fp-ts/Option';
import { useNavigate } from 'react-router-dom';
import SubscriptionLayout from '@layout/subscription/SubscriptionLayout';
import FormContent from '@shared/component/form/FormContent';

import * as Styled from './SubscriptionDetailForm.styles';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Checkbox, FormLabel, FormSubLabel, Input, Select } from '@styles/shared';
import { ErrorMessage } from '@styles/shared/error';
import { renderOptional } from '@shared/utils/render';
import { pipe } from 'fp-ts/function';
import * as SubscriptionService from '@modules/subscription/service';
import { filterEmptyStringToNullable } from '@shared/utils/string';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { queriesToSnakeCase, stringifyQueries } from '@shared/utils/queries';

interface SubscriptionDetailFormProps {
  company: Company;
  promotionCode: StripePromotionCodeId | null;
}

const SubscriptionDetailForm: FC<SubscriptionDetailFormProps> = ({ company, promotionCode }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<O.Option<string>>(O.none);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressFormBody>({
    resolver: zodResolver(addressSchema),
    defaultValues: {
      firstLine: '',
      secondLine: null,
      postalCode: '',
      city: '',
      country: Country.France,
    },
  });

  const [cgvAccepted, setCgvAccepted] = useState<boolean>(false);
  const [cgvError, setCgvError] = useState<boolean>(false);

  const handleCgvAcceptationChange: ChangeEventHandler<HTMLInputElement> = e => {
    const { checked } = e.target;

    setCgvError(false);
    setCgvAccepted(checked ?? false);
  };

  const handleSubmitAddress = (address: AddressFormBody) => {
    if (!cgvAccepted) {
      setCgvError(true);
    } else {
      setLoading(true);

      pipe(
        SubscriptionService.createCustomer({
          companyId: `${company.id}`,
          email: company.email,
          address: {
            ...address,
            name: company.label,
            secondLine: filterEmptyStringToNullable(address.secondLine),
          },
        }),
        TE.fold(
          error =>
            T.fromIO(() => {
              setError(
                pipe(
                  error.message,
                  O.alt(() => O.some('Une erreur est survenue.')),
                ),
              );
            }),
          ({ id }) =>
            T.fromIO(() => {
              const context: PaymentContext = {
                email: company.email,
                name: company.label,
                creationReason: company.creationReason,
                promotionCode,
              };

              navigate(
                { pathname: `/paiement/${id}`, search: stringifyQueries(queriesToSnakeCase(context)) },
                { replace: true },
              );
            }),
        ),
        T.chainIOK(() => () => setLoading(false)),
      )();
    }
  };

  return (
    <SubscriptionLayout step="detail">
      <form onSubmit={handleSubmit(handleSubmitAddress)} noValidate>
        <FormContent title="Souscrire à Team PRO">
          <Styled.SubscriptionFormDescription>
            Souscrivez à Team PRO et profitez d’une expérience mobile et web. Le Team PRO vous permettra de mieux
            surveiller les jardins de vos clients ou les zones de votre golf ou votre espace vert. Accédez aux
            prévisions météo et aux pourcentages de risques de Fusariose et DollarSpot dans les 5 prochains jours,
            travaillez en équipe, soyez propriétaire de vos données de signalements et participez activement à
            l’épidémio-surveillance des golfs et jardins.
          </Styled.SubscriptionFormDescription>

          <Styled.SubscriptionFormCompanyDetail>
            <p>E-mail</p>
            <p>{company.email}</p>

            <p>Nom Entreprise</p>
            <p>{company.label}</p>

            <p>Code Entreprise</p>
            <p>{company.siret}</p>
          </Styled.SubscriptionFormCompanyDetail>

          <Styled.SubscriptionFormContent>
            <FormLabel as="p" required>
              Adresse de facturation
            </FormLabel>

            <div>
              <Input id="firstLine" {...register('firstLine')} error={!!errors.firstLine} />
              <FormSubLabel htmlFor="firstLine">Adresse de facturation</FormSubLabel>
            </div>

            <div>
              <Input id="secondLine" {...register('secondLine')} error={!!errors.secondLine} />
              <FormSubLabel htmlFor="secondLine">Complément (optionnel)</FormSubLabel>
            </div>

            <Styled.SubscriptionFormCountry>
              <Select id="country" {...register('country')} error={!!errors.country}>
                {Object.keys(countryLabel).map(country => (
                  <option key={country} value={country}>
                    {countryLabel[country as Country]}
                  </option>
                ))}
              </Select>
              <FormSubLabel htmlFor="country">Pays</FormSubLabel>
            </Styled.SubscriptionFormCountry>

            <Styled.SubscriptionFormCity>
              <div>
                <Input id="postalCode" {...register('postalCode')} error={!!errors.postalCode} />
                <FormSubLabel htmlFor="postalCode">Code postal</FormSubLabel>
              </div>

              <div>
                <Input id="city" {...register('city')} error={!!errors.city} />
                <FormSubLabel htmlFor="city">Ville</FormSubLabel>
              </div>
            </Styled.SubscriptionFormCity>
          </Styled.SubscriptionFormContent>
        </FormContent>

        <Styled.CreateFormCgvContainer>
          <Checkbox id="cgv-acceptation" checked={cgvAccepted} onChange={handleCgvAcceptationChange} />

          <label htmlFor="cgv-acceptation">
            J’accepte les{' '}
            <a
              href="https://www.platform.garden/wp-content/uploads/2016/12/ConditionsGeneralesVentes-BDC-V2.pdf"
              target="_blank"
              rel="noreferrer">
              {' '}
              conditions générales de ventes plateforme.Garden
            </a>{' '}
            et notamment l’article 12 relatif à la Protection des données à caractère personnel.
          </label>
        </Styled.CreateFormCgvContainer>

        {cgvError && <ErrorMessage>Veuillez accepter les conditions générales de ventes.</ErrorMessage>}

        {renderOptional(error, error => (
          <ErrorMessage>{error}</ErrorMessage>
        ))}

        <Button type="submit" $loading={loading} disabled={loading}>
          Valider
        </Button>
      </form>
    </SubscriptionLayout>
  );
};

export default SubscriptionDetailForm;
