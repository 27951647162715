import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor } from '@styles/utils';
import { mediaMax } from '@styles/utils/responsive';

export const FormContentContainer = styled.div`
  padding: 50px 55px 60px;
  margin-bottom: 30px;
  border: 3px solid #c4c4c4;

  > h2 {
    padding-bottom: 20px;
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.light};
    color: ${primaryColor};
  }

  @media screen and ${mediaMax.tablet} {
    padding: 30px 0 0;
    border: none;
    border-top: 1px solid #c4c4c4;
  }
`;
