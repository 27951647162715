import styled, { css, keyframes } from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Button = styled.button<{ $loading?: boolean }>`
  display: inline-block;
  padding: 12px 38px;
  border: none;
  font-family: inherit;
  font-size: ${fontSize.standard};
  font-weight: ${fontWeight.bold};
  text-align: center;
  outline: none;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  -webkit-appearance: none;
  transition: all 0.15s linear;
  background: ${primaryColor};
  color: ${tertiaryColor(100)};
  letter-spacing: 0.01px;

  &:hover {
    background-color: #e64b3c;
  }

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `};

  ${props =>
    props.$loading &&
    css`
      position: relative;
      pointer-events: none;
      color: transparent;
      user-select: none;

      &:before {
        opacity: 1;
        border-radius: 50%;
        animation: ${spinnerAnimation} 1.2s linear infinite;
        content: '';
        position: absolute;
        height: 14px;
        width: 14px;
        top: calc(50% - (18px / 2));
        left: calc(50% - (18px / 2));
        border: 2px solid #fff;
        border-top-color: transparent;
      }
    `}
`;
