import styled from 'styled-components';
import { primaryColor, tertiaryColor } from '../../utils';

export const Radio = styled.input.attrs(props => ({
  type: props.type ?? 'radio',
}))`
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;
  border-radius: 100%;
  background: #cecece;
  cursor: pointer;
  outline: none;
  padding: 10px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: ${tertiaryColor(100)};
    border: 3px solid ${tertiaryColor(100)};
    box-sizing: border-box;
    border-radius: 100%;
    transition: background-color 0.15s ease-in-out;
  }

  &:checked {
    &:before {
      background: ${primaryColor};
    }
  }
`;
