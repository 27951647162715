import React, { FC } from 'react';
import PaymentSuccess from '@modules/subscription/components/success/PaymentSuccess';

interface PaymentSuccessPgeProps {}

const PaymentSuccessPge: FC<PaymentSuccessPgeProps> = () => {
  return <PaymentSuccess />;
};

export default PaymentSuccessPge;
