import styled, { keyframes } from 'styled-components';
import { tertiaryColor } from '@styles/utils';

export const GlobalLoaderOverlay = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
  background: rgba(0, 0, 0, 0.19);
`;

export const GlobalLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 71px;
  height: 71px;
  border-radius: 15px;
  background: ${tertiaryColor(100)};
`;

const loaderAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const GlobalLoaderSpinner = styled.div`
  position: relative;
  width: 40px;
  height: 40px;

  > div {
    transform-origin: 20px 21px;
    animation: ${loaderAnimation} 0.8s linear infinite;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 19px;
      width: 2px;
      height: 12px;
      border-radius: 3px;
      background: #000;
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -0.8s;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -0.7s;
    }

    &:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -0.6s;
    }

    &:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -0.5s;
    }

    &:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -0.4s;
    }

    &:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -0.3s;
    }

    &:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }

    &:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -0.1s;
    }
  }
`;
