import styled from 'styled-components';
import { primaryColor, tertiaryColor } from '@styles/utils';
import { mediaMin } from '@styles/utils/responsive';

export const HeaderContainer = styled.header`
  height: 76px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${tertiaryColor(200)};
  background-color: ${tertiaryColor(100)};
  z-index: 1000;
`;

export const HeaderContent = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  max-width: 1190px;
  height: 100%;
  padding: 0 25px;
  margin: 0 auto;

  @media screen and ${mediaMin.desktop} {
    > img {
      margin-top: -10px;
    }
  }
`;

export const HeaderBurger = styled.button<{ $open: boolean }>`
  position: absolute;
  width: 45px;
  height: 45px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background: none;

  @media screen and ${mediaMin.desktop} {
    display: none;
  }

  > span {
    display: block;
    position: absolute;
    left: 10px;
    width: 25px;
    height: 2px;
    background-color: ${props => (props.$open ? primaryColor : tertiaryColor(900))};
    transition: background-color 0.15s linear;

    &:nth-child(1) {
      top: 13px;
    }

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      bottom: 12px;
    }
  }
`;
