import styled, { css } from 'styled-components';
import { fontSize, primaryColor, tertiaryColor } from '@styles/utils';

export const FormLabel = styled.label<{ required?: boolean }>`
  display: block;
  font-size: ${fontSize.large};
  padding-bottom: 3px;

  ${props =>
    props.required &&
    css`
      &:after {
        content: '*';
        margin-left: 3px;
        color: ${primaryColor};
      }
    `}
`;

export const FormSubLabel = styled.label`
  font-size: ${fontSize.small};
  color: ${tertiaryColor(800)};
`;
