import { Color, DefaultTheme, ThemeProps } from 'styled-components';

export function primaryColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.primary;
}

export function secondaryColor({ theme }: ThemeProps<DefaultTheme>): string {
  return theme.colors.secondary;
}

function getColor<C extends Record<string, string>>(
  color: C,
  defaultKey: Extract<keyof C, number>,
  key?: Extract<keyof C, number>,
): string {
  return color[key ?? defaultKey];
}

export function tertiaryColor(key?: Extract<keyof Color, number>): (props: ThemeProps<DefaultTheme>) => string {
  return ({ theme }) => getColor(theme.colors.tertiary, 900, key);
}
