import queryString, { ParsedQuery, StringifiableRecord } from 'query-string';

import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import * as String from 'fp-ts/string';
import { pipe } from 'fp-ts/function';
import { camelToSnake, snakeToCamel } from '@shared/utils/string';

export function queriesToSnakeCase(queries: StringifiableRecord): StringifiableRecord {
  return pipe(
    queries,
    R.reduceWithIndex(String.Ord)({} as StringifiableRecord, (key, acc, value) => ({
      ...acc,
      [camelToSnake(key)]: value,
    })),
  );
}

export function queriesToCamelCase(queries: ParsedQuery): ParsedQuery {
  return pipe(
    queries,
    R.reduceWithIndex(String.Ord)({} as ParsedQuery, (key, acc, value) => ({
      ...acc,
      [snakeToCamel(key)]: value,
    })),
  );
}

export function stringifyQueries(queries: StringifiableRecord): string {
  return queryString.stringify(queries, { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' });
}

export function parseQueries(queries: string): ParsedQuery {
  return queryString.parse(queries, { arrayFormat: 'comma' });
}

function getQueryValue<T extends string = string>(query: ParsedQuery, key: string): O.Option<T> {
  return pipe(
    O.fromNullable(query[key] as T | T[] | null),
    O.chain(value => (Array.isArray(value) ? A.head(value) : O.some(value))),
  );
}

export function getStringQuery<T extends string = string>(query: ParsedQuery, key: string): T | null {
  return O.toNullable(getQueryValue(query, key));
}
