import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  font: {
    family: "'Roboto', sans-serif",
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
    },
    sizes: {
      h1: '40px',
      h2: '30px',
      h3: '20px',
      large: '16px',
      standard: '14px',
      small: '13px',
    },
  },
  colors: {
    primary: '#e1523d',
    secondary: '#43b02a',
    tertiary: {
      100: '#fff',
      200: '#f5f4f2',
      300: '#e2ded8',
      400: '#a39382',
      500: '#7b6756',
      600: '#483729',
      700: '#363636',
      800: '#2e2e2e',
      900: '#000',
    },
  },
  breakpoints: {
    large: 1280,
    desktop: 960,
    tablet: 760,
    mobile: 560,
  },
};

export default theme;
