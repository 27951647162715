import styled from 'styled-components';
import { fontSize, fontWeight, secondaryColor, tertiaryColor } from '@styles/utils';

import checkIcon from '@assets/icons/check.svg';

export const PaymentSuccessContainer = styled.div`
  max-width: 777px;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
`;

export const PaymentSuccessContent = styled.div`
  padding: 40px 20px;
  background: ${tertiaryColor(200)};

  > h1 {
    font-size: ${fontSize.h1};
    color: ${tertiaryColor(800)};
  }

  > p {
    max-width: 545px;
    margin: 0 auto;
    font-weight: ${fontWeight.light};
    line-height: 2.29;
    letter-spacing: 0.01px;
  }
`;

export const PaymentSuccessIcon = styled.div`
  width: 35px;
  height: 35px;
  margin: 0 auto;
  background: url(${checkIcon}) no-repeat center center ${secondaryColor};
  background-size: 18px;
  border-radius: 100%;
`;

export const PaymentSuccessReturnLink = styled.a`
  display: inline-block;
  margin-top: 30px;
  font-size: 12px;
  color: ${tertiaryColor(800)};
  text-decoration: underline;
`;
