import React, { FC, useState } from 'react';

import * as Styled from './Nav.styles';
import { useTheme } from 'styled-components';

type MenuIndex = 0 | 1 | 2;

interface NavProps {
  open: boolean;
}

const Nav: FC<NavProps> = ({ open }) => {
  const theme = useTheme();

  const [menuOpen, setMenuOpen] = useState<MenuIndex | null>(null);

  const handleToggleMenu = (index: MenuIndex) => () => {
    if (window.innerWidth <= theme.breakpoints.desktop) {
      setMenuOpen(old => (old === index ? null : index));
    }
  };

  return (
    <Styled.NavContainer $open={open}>
      <Styled.NavList>
        <Styled.NavElement>
          <a href="https://www.platform.garden">Accueil</a>
        </Styled.NavElement>

        <Styled.NavElement onClick={handleToggleMenu(0)}>
          <span>Nos solutions digitales</span>

          <Styled.NavSubMenu $open={menuOpen === 0}>
            <li>
              <a href="https://www.platform.garden/application-platform-garden">L'application PLATFORM.GARDEN</a>
            </li>
            <li>
              <a href="https://www.platform.garden/les-objets-connectes-hortee/">Les objets connectés HORTEE</a>
            </li>
            <li>
              <a href="https://www.platform.garden/le-site-portail-pro/">Le site PORTAIL.PRO</a>
            </li>
            <li>
              <a href="https://www.platform.garden/nos-tarifs/">Nos Tarifs</a>
            </li>
          </Styled.NavSubMenu>
        </Styled.NavElement>

        <Styled.NavElement onClick={handleToggleMenu(1)}>
          <span>Nos univers</span>

          <Styled.NavSubMenu $open={menuOpen === 1}>
            <li>
              <a href="https://www.platform.garden/fabricants-et-distributeurs/">Fabricants et Distributeurs</a>
            </li>
            <li>
              <a href="https://www.platform.garden/paysagistes/">Paysagistes</a>
            </li>
            <li>
              <a href="https://www.platform.garden/golfs-et-terrains-de-sport/">Golfs et Terrains de sport</a>
            </li>
          </Styled.NavSubMenu>
        </Styled.NavElement>

        <Styled.NavElement onClick={handleToggleMenu(2)}>
          <span>Qui sommes nous ?</span>

          <Styled.NavSubMenu $open={menuOpen === 2}>
            <li>
              <a href="https://www.platform.garden/notre-equipe/">Notre équipe</a>
            </li>
            <li className="">
              <a href="https://www.platform.garden/platform-garden-la-story/">Les dates clés</a>
            </li>
            <li className="">
              <a href="https://www.platform.garden/contact/">Contact</a>
            </li>
          </Styled.NavSubMenu>
        </Styled.NavElement>

        <Styled.NavElement>
          <a href="https://www.platform.garden/connexion">Se connecter</a>
        </Styled.NavElement>
      </Styled.NavList>
    </Styled.NavContainer>
  );
};

export default Nav;
