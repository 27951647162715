import { pipe } from 'fp-ts/function';
import theme from '@styles/theme';
import * as R from 'fp-ts/Record';
import { BreakPoints, DefaultTheme, ThemeProps } from 'styled-components';
import * as String from 'fp-ts/string';

export const mediaMin = pipe(
  theme.breakpoints,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<Extract<keyof BreakPoints, string>, (props: ThemeProps<DefaultTheme>) => string>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => `(min-width: ${props.theme.breakpoints[key] + 1}px)`,
    }),
  ),
);

export const mediaMax = pipe(
  theme.breakpoints,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<Extract<keyof BreakPoints, string>, (props: ThemeProps<DefaultTheme>) => string>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => `(max-width: ${props.theme.breakpoints[key]}px)`,
    }),
  ),
);
