import React, { FC, useEffect, useState } from 'react';

import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
`;

export const LineLoader = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 100%;
  overflow: hidden;
  z-index: 10000;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 3px;
    background-color: ${props => props.theme.colors.primary};
    animation: ${loadingAnimation} 2s linear infinite;
  }
`;

export const DebouncedLineLoader: FC = () => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setShow(true);
    }, 500);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, []);

  return show ? <LineLoader /> : null;
};

export default LineLoader;
