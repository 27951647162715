import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor } from '@styles/utils';

export const SubscriptionDetailErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 20px;

  img {
    max-width: 100%;
    height: auto;
  }

  h1 {
    display: inline-block;
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.light};
    border-bottom: 5px solid ${primaryColor};
  }

  p {
    padding: 20px 0 30px;
    font-size: 18px;
    font-weight: ${fontWeight.light};
  }

  a {
    padding: 12px 18px;
  }
`;
