import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';
import { mediaMax } from '@styles/utils/responsive';

export const SubscriptionFormDescription = styled.p`
  padding-bottom: 30px;
  font-weight: ${fontWeight.light};
  line-height: 2.29;
`;

export const SubscriptionFormCompanyDetail = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-gap: 15px 25px;
  padding: 30px 20px;
  background: ${tertiaryColor(200)};
  font-size: ${fontSize.large};

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:nth-child(even) {
      opacity: 0.4;
    }
  }
`;

export const SubscriptionFormContent = styled.div`
  padding-top: 50px;
  width: 50%;

  @media screen and ${mediaMax.desktop} {
    width: 100%;
  }
`;

export const SubscriptionFormCity = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
  padding-top: 20px;
`;

export const SubscriptionFormCountry = styled.div`
  padding-top: 10px;
`;

export const CreateFormCgvContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 20px;
  padding: 15px 20px;
  margin: 0 0 30px;
  background: #f5f5f5;

  > input {
    margin-top: 7px;
  }

  > label {
    font-weight: ${fontWeight.light};
    line-height: 2.29;
    max-width: 550px;
    cursor: pointer;

    > a {
      color: ${primaryColor};
      text-decoration: none;
    }
  }
`;
