import React, { FC, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFetchTask } from '@core/http';

import * as SubscriptionService from '@modules/subscription/service';
import { renderHttpRemoteData } from '@shared/utils/render';
import SubscriptionDetailForm from '@modules/subscription/components/detail/SubscriptionDetailForm';
import SubscriptionDetailError from '@modules/subscription/components/detail/error/SubscriptionDetailError';
import { getStringQuery, parseQueries, queriesToCamelCase } from '@shared/utils/queries';
import { StripePromotionCodeId } from '@modules/subscription/model';

const SubscriptionDetailPage: FC = () => {
  const token = useParams().token as string;

  const location = useLocation();

  const [company] = useFetchTask(SubscriptionService.getCompanyFromToken, token);

  const promotionCode = useMemo(
    () => getStringQuery<StripePromotionCodeId>(queriesToCamelCase(parseQueries(location.search)), 'promotionCode'),
    [location.search],
  );

  return renderHttpRemoteData(
    company,
    company => <SubscriptionDetailForm company={company} promotionCode={promotionCode} />,
    undefined,
    error => <SubscriptionDetailError error={error} />,
  );
};

export default SubscriptionDetailPage;
