import React, { FC, useEffect } from 'react';

import * as Styled from './SubscriptionDetailError.styles';

import expiredImage from '@assets/images/expired.png';
import expiredImage2x from '@assets/images/expired@2x.png';
import { Button } from '@styles/shared';
import { HttpError } from '@core/http';
import { logSentryHttpError } from '@shared/modules/sentry/utils';

interface SubscriptionDetailErrorProps {
  error: HttpError;
}

const SubscriptionDetailError: FC<SubscriptionDetailErrorProps> = ({ error }) => {
  useEffect(() => {
    logSentryHttpError('[subscription] cannot get subscription by token', error, 'warning');
  }, [error]);

  return (
    <Styled.SubscriptionDetailErrorContainer>
      <img width={607} height={510} src={expiredImage} srcSet={`${expiredImage2x} 2x`} alt="Lien expiré" />

      <h1>Ce lien a expiré !</h1>

      <p>Veuillez contacter notre service client.</p>

      <Button as="a" href="https://www.platform.garden/contact/">
        Nous contacter
      </Button>
    </Styled.SubscriptionDetailErrorContainer>
  );
};

export default SubscriptionDetailError;
