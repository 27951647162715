import React, { FC, PropsWithChildren } from 'react';

import * as Styled from './FormContent.styles';

interface FormContentProps {
  title: string;
}

const FormContent: FC<PropsWithChildren<FormContentProps>> = ({ title, children }) => (
  <Styled.FormContentContainer>
    <h2>{title}</h2>

    {children}
  </Styled.FormContentContainer>
);

export default FormContent;
