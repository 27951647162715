import { z } from 'zod';
import { Newtype } from 'newtype-ts';
import { StringifiableRecord } from 'query-string';

export type CompanyId = Newtype<{ readonly ID: unique symbol }, number> & number;
export type CustomerId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type PriceId = Newtype<{ readonly ID: unique symbol }, string> & string;
export type StripePromotionCodeId = Newtype<{ readonly ID: unique symbol }, string> & string;

export const PENDING_COMPANY_STORAGE_KEY = 'pending-company';

export enum CompanyKind {
  Landscaper = 'landscaper',
  Greenkeeper = 'greenkeeper',
  Collectivity = 'collectivity',
}

export const companyKindLabel: Record<CompanyKind, string> = {
  [CompanyKind.Landscaper]: 'Paysagiste',
  [CompanyKind.Greenkeeper]: 'Sport',
  [CompanyKind.Collectivity]: 'Collectivité',
};

export enum Country {
  France = 'france',
}

export const countryLabel: Record<Country, string> = {
  [Country.France]: 'France',
};

const postalCodeSchema = z
  .string()
  .nonempty()
  .regex(/^[0-9]*$/);

export const addressSchema = z.object({
  firstLine: z.string().nonempty(),
  secondLine: z.string().nullable(),
  postalCode: postalCodeSchema,
  city: z.string().nonempty(),
  country: z.string().nonempty(),
});

export type AddressFormBody = z.infer<typeof addressSchema>;

export interface Address extends AddressFormBody {
  name: string;
}

export const createCompanySchema = z.object({
  kind: z.nativeEnum(CompanyKind),
  unep: z.boolean(),
  email: z.string().email().nonempty(),
  phoneNumber: z.string().nonempty(),
  company: z.string().nonempty(),
  firstName: z.string().nonempty(),
  lastName: z.string().nonempty(),
  country: z.nativeEnum(Country),
  postalCode: postalCodeSchema,
  city: z.string().nonempty(),
  siret: z.string().nonempty().max(14),
  address: addressSchema.required(),
});

export type CreateCompanyFormBody = z.infer<typeof createCompanySchema>;

export type CreateCompanyParams = Omit<CreateCompanyFormBody, 'address'>;

export interface Company {
  id: CompanyId;
  email: string;
  label: string;
  siret: string | null;
  creationReason: string;
}

export const paymentSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('sepa'),
  }),
  z.object({ type: z.literal('card'), name: z.string().nonempty() }),
]);

export type PaymentFormBody = z.infer<typeof paymentSchema>;

export interface CreateCustomerParams {
  companyId: string;
  email: string;
  address: Address;
}

export interface CreateSubscriptionParams {
  customerId: CustomerId;
  priceId: PriceId;
  creationReason: string;
  promotionCodeId: StripePromotionCodeId | null;
}

export interface CreateSubscriptionResponse {
  id: string;
  secret: string;
}

export interface StripeError {
  type: 'stripe';
  message: string;
}

export interface TechnicalError {
  type: 'technical';
}

export interface PollingError {
  type: 'polling';
}

export type PaymentError = StripeError | TechnicalError | PollingError;

export interface SubscriptionProducts {
  plan: SubscriptionPlan;
}

export interface SubscriptionPlan {
  id: string;
  prices: Array<SubscriptionPrice>;
}

export interface SubscriptionPrice {
  id: PriceId;
  recurringInterval: string;
  unitAmount: number;
  discount: SubscriptionPriceDiscount | null;
}

export interface SubscriptionPriceDiscount {
  percentage: number;
  unitAmount: number;
}

export interface CustomerStorageValue {
  customerId: string;
  email: string;
  company: string;
}

export interface PaymentContext extends StringifiableRecord {
  email: string;
  name: string;
  creationReason: string;
  promotionCode: StripePromotionCodeId | null;
}
