import localforage from 'localforage';

import * as TO from 'fp-ts/TaskOption';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import { constFalse, constVoid, pipe } from 'fp-ts/function';

const storage = localforage.createInstance({
  name: 'pg-subscription',
  storeName: 'storage',
  version: 1,
  description: 'Platform.Garden subscription storage',
});

function getItem<A>(key: string): TO.TaskOption<A> {
  return pipe(
    TE.tryCatch(() => storage.getItem<A>(key), constFalse),
    TE.chainOptionK(constFalse)(O.fromNullable),
    TO.fromTaskEither,
  );
}

function setItem<A>(key: string, value: A): T.Task<void> {
  return pipe(
    TE.tryCatch(() => storage.setItem(key, value), constFalse),
    TE.fold(
      () => T.of(false),
      () => T.of(true),
    ),
    T.map(constVoid),
  );
}

function removeItem(key: string): T.Task<void> {
  return pipe(
    TE.tryCatch(() => storage.removeItem(key), constFalse),
    TE.fold(
      () => T.of(false),
      () => T.of(true),
    ),
    T.map(constVoid),
  );
}

const storageService = {
  getItem,
  setItem,
  removeItem,
};

export default storageService;
