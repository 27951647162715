import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

export const Input = styled.input.attrs(props => ({
  type: props.type ?? 'text',
}))<{ error?: boolean }>`
  display: block;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  margin: 0;
  outline: none;
  border: none;
  box-sizing: border-box;
  border-bottom: 1px solid ${props => (props.error ? primaryColor : '#b7b7b7')};
  font-family: inherit;
  font-size: ${fontSize.standard};
  font-weight: ${fontWeight.light};
  color: ${props => (props.error ? primaryColor : tertiaryColor(900))};
  transition: all 0.15s linear;

  &:focus {
    border-bottom-color: ${props => (props.error ? primaryColor : tertiaryColor(700))};
  }
`;
