import styled from 'styled-components';

import checkIcon from '@assets/icons/check.svg';
import { primaryColor, tertiaryColor } from '@styles/utils';

export const Checkbox = styled.input.attrs(() => ({ type: 'checkbox' }))`
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0;
  border: 1px solid #b7b7b7;
  background-color: ${tertiaryColor(100)};
  cursor: pointer;
  outline: none;
  transition: border-color 0.15s linear, background-color 0.15s linear;

  &:checked {
    background: url(${checkIcon}) no-repeat 3px 4px ${primaryColor};
    border-color: ${primaryColor};
  }
`;
