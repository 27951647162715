import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '@styles/utils';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    width: 500px;
    max-width: 100%;
  }

  > h1 {
    padding: 20px 0;
    font-size: ${fontSize.h1};
    text-transform: uppercase;
    color: ${tertiaryColor(800)};
  }

  > a {
    color: ${primaryColor};
    font-weight: ${fontWeight.medium};
    text-decoration: none;
  }
`;
