import React, { FC, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import * as Styled from './GlobalLoader.styles';

const GlobalLoader: FC = () => {
  useLayoutEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return createPortal(
    <Styled.GlobalLoaderOverlay>
      <Styled.GlobalLoaderContainer>
        <Styled.GlobalLoaderSpinner>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </Styled.GlobalLoaderSpinner>
      </Styled.GlobalLoaderContainer>
    </Styled.GlobalLoaderOverlay>,
    document.body,
  );
};

export default GlobalLoader;
