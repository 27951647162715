import styled from 'styled-components';
import { fontSize, primaryColor, tertiaryColor } from '@styles/utils';

export const ErrorMessage = styled.div`
  padding: 15px 20px;
  margin-bottom: 30px;
  background: ${tertiaryColor(200)};
  font-size: ${fontSize.small};
  color: ${primaryColor};
`;
