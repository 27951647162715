import React, { FC, useState } from 'react';

import * as Styled from './Header.styles';

import logo from '@assets/logos/logo-platform-garden.svg';
import Nav from './nav/Nav';

const Header: FC = () => {
  const [navOpen, setNavOpen] = useState<boolean>(false);

  const handleBurgerClick = () => setNavOpen(old => !old);

  return (
    <Styled.HeaderContainer>
      <Styled.HeaderContent>
        <a href="https://www.platform.garden/">
          <img src={logo} alt="Logo Platform.Garden" />
        </a>

        <Nav open={navOpen} />

        <Styled.HeaderBurger $open={navOpen} onClick={handleBurgerClick}>
          <span />
          <span />
          <span />
        </Styled.HeaderBurger>
      </Styled.HeaderContent>
    </Styled.HeaderContainer>
  );
};

export default Header;
