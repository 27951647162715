import {
  Company,
  CompanyId,
  CreateCompanyParams,
  CreateCustomerParams,
  CreateSubscriptionParams,
  CreateSubscriptionResponse,
  CustomerId,
  StripePromotionCodeId,
  SubscriptionPrice,
  SubscriptionProducts,
} from '@modules/subscription/model';
import { HttpError, httpService, HttpTask } from '@core/http';
import { pipe } from 'fp-ts/function';
import * as EI from 'fp-ts/Either';
import * as Retry from 'retry-ts';
import { retrying } from 'retry-ts/Task';
import { AxiosRequestConfig } from 'axios';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';
import config from '@config/index';

const POLLING_INTERVAL = 3000;
const POLLING_MAX_ITERATIONS = 30;

const subscriptionServerOptions: AxiosRequestConfig = {
  baseURL: config.VITE_SUBSCRIPTION_API_URL,
  headers: {
    Authorization: `Bearer ${config.VITE_SUBSCRIPTION_API_BEARER}`,
  },
};

export function createCompany(params: CreateCompanyParams): HttpTask<{ id: CompanyId }> {
  return httpService.post('/front/subscriptions', params);
}

export function createCustomer(params: CreateCustomerParams): HttpTask<{ id: CustomerId }> {
  return httpService.post('/customers', params, subscriptionServerOptions);
}

export function getProducts(promotionCode: StripePromotionCodeId | null): HttpTask<SubscriptionProducts> {
  return httpService.get('/products', { ...subscriptionServerOptions, params: { promotion_code: promotionCode } });
}

export function getFirstPrice(promotionCode: StripePromotionCodeId | null): HttpTask<SubscriptionPrice> {
  return pipe(
    getProducts(promotionCode),
    TE.chainOptionK(() => HttpError.notFound)(products => A.head(products.plan.prices)),
  );
}

export function createSubscription(params: CreateSubscriptionParams): HttpTask<CreateSubscriptionResponse> {
  return httpService.post('/subscriptions', params, subscriptionServerOptions);
}

export function subscriptionPolling(subscriptionId: string): HttpTask {
  const policy = Retry.Monoid.concat(Retry.limitRetries(POLLING_MAX_ITERATIONS), Retry.constantDelay(POLLING_INTERVAL));

  return retrying(
    policy,
    () => httpService.get(`/subscriptions/by/id/${subscriptionId}`, subscriptionServerOptions),
    EI.isLeft,
  );
}

export function getCompanyFromToken(token: string): HttpTask<Company> {
  return httpService.get('/front/subscriptions', { params: { token } });
}
