import React, { FC, useMemo } from 'react';

import * as Styled from './ErrorPage.styles';
import logo from '@assets/logos/logo-platform-garden.svg';
import { HttpError, HttpStatusCode } from '@core/http';

interface ErrorPageProps {
  error?: HttpError;
  internal?: boolean;
}

const ErrorPage: FC<ErrorPageProps> = ({ error = HttpError.notFound, internal }) => {
  const title = useMemo(() => {
    if (internal) {
      return 'Erreur technique interne';
    } else if (!error || error.status === HttpStatusCode.NOT_FOUND) {
      return 'Page non trouvée';
    } else {
      return 'Erreur technique';
    }
  }, [error, internal]);

  return (
    <Styled.ErrorContainer>
      <img src={logo} alt="Logo Platform.Garden" />

      <h1>{title}</h1>

      <a href="https://www.platform.garden/">Revenir à l'accueil</a>
    </Styled.ErrorContainer>
  );
};

export default ErrorPage;
