import React, { FC } from 'react';

import * as Styled from './PaymentSuccess.styles';

interface PaymentSuccessProps {}

const PaymentSuccess: FC<PaymentSuccessProps> = () => {
  return (
    <Styled.PaymentSuccessContainer>
      <Styled.PaymentSuccessContent>
        <Styled.PaymentSuccessIcon />

        <h1>Paiement terminé</h1>

        <p>
          Merci pour votre achat. Vous allez recevoir un mail d’activation de votre compte ainsi qu’un mail de
          confirmation d’abonnement pour accéder à nos plateformes !
        </p>
      </Styled.PaymentSuccessContent>

      <Styled.PaymentSuccessReturnLink href="https://www.platform.garden/">
        Revenir à l’accueil
      </Styled.PaymentSuccessReturnLink>
    </Styled.PaymentSuccessContainer>
  );
};

export default PaymentSuccess;
