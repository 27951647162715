import React, { FC } from 'react';

import visa from '@assets/icons/cards/visa.png';
import visa2x from '@assets/icons/cards/visa@2x.png';
import visa3x from '@assets/icons/cards/visa@3x.png';

import masterCard from '@assets/icons/cards/masterCard.png';
import masterCard2x from '@assets/icons/cards/masterCard@2x.png';
import masterCard3x from '@assets/icons/cards/masterCard@3x.png';

import maestroCard from '@assets/icons/cards/maestro.png';
import maestroCard2x from '@assets/icons/cards/maestro@2x.png';
import maestroCard3x from '@assets/icons/cards/maestro@3x.png';

import AM from '@assets/icons/cards/AM.png';
import AM2x from '@assets/icons/cards/AM@2x.png';
import AM3x from '@assets/icons/cards/AM@3x.png';

import * as Styled from './PaymentCardsIcons.styles';

const PaymentCardsIcons: FC = () => (
  <Styled.PaymentCardsIconsContainer>
    <img src={visa} srcSet={`${visa2x} 2x, ${visa3x} 3x`} alt="Visa" />
    <img src={masterCard} srcSet={`${masterCard2x} 2x, ${masterCard3x} 3x`} alt="MasterCard" />
    <img src={maestroCard} srcSet={`${maestroCard2x} 2x, ${maestroCard3x} 3x`} alt="Maestro" />
    <img src={AM} srcSet={`${AM2x} 2x, ${AM3x} 3x`} alt="American Express" />
  </Styled.PaymentCardsIconsContainer>
);

export default PaymentCardsIcons;
