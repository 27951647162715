import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

export function filterEmptyStringToOption<T extends string>(value: T | null | undefined): O.Option<T> {
  return pipe(
    O.fromNullable(value),
    O.filter(v => v !== ''),
  );
}

export function filterEmptyStringToNullable<T extends string>(value: T | null | undefined): string | null {
  return O.toNullable(filterEmptyStringToOption(value));
}

export function camelToSnake(value: string): string {
  return value.replace(/[\w]([A-Z])/g, m => m[0] + '_' + m[1]).toLowerCase();
}

export function snakeToCamel(value: string): string {
  return value.replace(/(_\w)/g, m => m[1].toUpperCase());
}
