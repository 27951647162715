import React, { FC, useMemo } from 'react';

import * as Styled from './Footer.styles';

import logo from '@assets/logos/logo-platform-garden-light.svg';

import facebook from '@assets/icons/social/facebook.svg';
import instagram from '@assets/icons/social/instagram.svg';
import youtube from '@assets/icons/social/youtube.svg';
import linkedin from '@assets/icons/social/linkedin.svg';

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Styled.FooterContainer>
      <Styled.FooterTop>
        <Styled.FooterFirstColumn>
          <img src={logo} alt="Logo Platform.Garden" />

          <p>2 rue Galeben, Mios, France.</p>

          <p>
            <span>contact@platform.garden</span> 05 47 50 02 99
          </p>

          <Styled.FooterSocial>
            <a target="_blank" href="https://www.facebook.com/platform.garden" rel="noreferrer">
              <img src={facebook} alt="Facebook" />
            </a>
            <a target="_blank" href="https://www.instagram.com/platform.garden/?hl=fr" rel="noreferrer">
              <img src={instagram} alt="Instagram" />
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCSVLkItITEFzMh1B2d5jCbw" rel="noreferrer">
              <img src={youtube} alt="Youtube" />
            </a>
            <a target="_blank" href="https://fr.linkedin.com/company/platform-garden" rel="noreferrer">
              <img src={linkedin} alt="LinkedIn" />
            </a>
          </Styled.FooterSocial>
        </Styled.FooterFirstColumn>

        <Styled.FooterList>
          <h3>Menu</h3>
          <a href="https://www.platform.garden">Accueil</a>
          <a href="https://www.platform.garden/contact">Contacts</a>
          <a href="https://portailpro.platform.garden/" target="_blank" rel="noreferrer">
            Portail PROS
          </a>
          <a href="https://app.platform.garden/login" target="_blank" rel="noreferrer">
            Portail FOURNISSEURS
          </a>
        </Styled.FooterList>

        <Styled.FooterList>
          <h3>Pages</h3>
          <a href="http://www.platform.garden/application-platform-garden/">Appli PLATFORM.GARDEN</a>
          <a href="http://www.platform.garden/les-objets-connectes-hortee/">IOT HORTEE</a>
          <a href="http://www.platform.garden/le-site-portail-pro/">Notre portail pour les PROS</a>
          <a href="http://www.platform.garden/nos-tarifs/">Tarifs</a>
          <a href="http://www.platform.garden/cgu-mentions-legales/">Mentions Legales</a>
        </Styled.FooterList>

        <Styled.FooterList>
          <h3>Nos marques</h3>

          <p>PLATFORM GARDEN, Solutions digitales de l’espace vert.</p>
          <p>PLATFORM GARDEN, l’App des pros de l’espace vert.</p>
          <p>HORTEE, Objets Connectés au service des espaces verts.</p>
          <p>MYAPP GARDEN, l’App des amateurs de jardins.</p>
        </Styled.FooterList>
      </Styled.FooterTop>
      <Styled.FooterBottom>&copy; {year} PLATFORM.GARDEN</Styled.FooterBottom>
    </Styled.FooterContainer>
  );
};

export default Footer;
